import { Dict } from 'mixpanel-browser';

export class GenericAnalyticsEventPayloadBuilder {
	protected payload: Dict = {};

	build(): Dict {
		return this.payload;
	}

	protected millisecondsToMinutes(milliseconds: number): number {
		return +(milliseconds / 1000 / 60).toFixed(2);
	}

	protected timestampToString(timestamp: number): string {
		return timestamp ? new Date(timestamp).toISOString().substring(0, 10) : '';
	}
}
